import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Menu from "@/components/menu/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    //登录
    {
        path: '/login',
        component: () => import('@/views/login/index.vue'),
        meta: { hidden: true },
    },
    //商户管理
    {
        path: "/",
        component: Menu,
        redirect: "/merchant/merchantList",
        name: "merchant",
        meta: {
            title: "商户管理",
            icon: "el-icon-s-shop"
        },
        children: [
            {
                path: "/merchant/merchantList",
                name: "merchantList",
                component: () => import("@/views/merchant/merchantList.vue"),
                meta: { title: "商户列表" }
            },
            {
                path: "/merchant/preidDistributeLog",
                name: "preidDistributeLog",
                component: () => import("@/views/merchant/preidDistributeLog.vue"),
                meta: { title: "标识分配" }
            },
            {
                path: "/merchant/merchantAppkey",
                name: "merchantAppkey",
                component: () => import("@/views/merchant/merchantAppkey.vue"),
                meta: { title: "商户appkey" }
            },
            {
                path: "/merchant/warningSetting",
                name: "warningSetting",
                component: () => import("@/views/merchant/warningSetting.vue"),
                meta: { title: "预警设置" }
            },
        ],
    },
    //财务管理
    {
        path: "/finance",
        component: Menu,
        redirect: "/finance/financeManage",
        name: "finance",
        meta: {
            title: "财务管理",
            icon: "el-icon-s-goods"
        },
        children: [
            {
                path: "/finance/financeManage",
                name: "financeManage",
                component: () => import("@/views/finance/financeManage.vue"),
                meta: { title: "财务总表" }
            },
            {
                path: "/finance/rechargeFundRecord",
                name: "rechargeFundRecord",
                component: () => import("@/views/finance/rechargeFundRecord.vue"),
                meta: { title: "充值退款明细" }
            },
            {
                path: "/finance/wxReconciliation",
                name: "wxReconciliation",
                component: () => import("@/views/finance/wxReconciliation.vue"),
                meta: { title: "微信对账表" }
            },
        ],
    },
    //下载中心
    {
        path: "/download",
        component: Menu,
        redirect: "/download/download",
        name: "download",
        meta: {
            title: "文件下载",
            icon: "el-icon-download"
        },
        children: [
            {
                path: "/download/download",
                name: "downloadDownload",
                component: () => import("@/views/download/download.vue"),
                meta: { title: "下载中心" }
            }
        ],
    },
    //财务管理
    {
        path: "/videovips",
        component: Menu,
        redirect: "/videovips/videoResources",
        name: "videovips",
        meta: {
            title: "视频资源管理",
            icon: "el-icon-s-goods"
        },
        children: [
            {
                path: "/videovips/videoResources",
                name: "videovipsResources",
                component: () => import("@/views/videovips/videoResources.vue"),
                meta: { title: "视频资源列表" }
            },
            {
                path: "/videovips/accountOverview",
                name: "accountOverview",
                component: () => import("@/views/videovips/accountOverview.vue"),
                meta: { title: "账户总览" }
            },
            {
                path: "/videovips/deductionDetails",
                name: "deductionDetails",
                component: () => import("@/views/videovips/deductionDetails.vue"),
                meta: { title: "扣款明细" }
            },
            {
                path: "/videovips/financeDepartment",
                name: "financeDepartment",
                component: () => import("@/views/videovips/financeDepartment.vue"),
                meta: { title: "充值退款明细" }
            },
            {
                path: "/videovips/refundRequest",
                name: "refundRequest",
                component: () => import("@/views/videovips/refundRequest.vue"),
                meta: { title: "退款申请" }
            },
            // {
            //     path: "/videovips/capitalFlow",
            //     name: "capitalFlow",
            //     component: () => import("@/views/videovips/capitalFlow.vue"),
            //     meta: { title: "资金流水对账表" }
            // },
        ],
    },
    //用户中心
    {
        path: "/user",
        component: Menu,
        redirect: "/user/userList",
        name: "user",
        meta: {
            title: "用户中心",
            icon: "el-icon-s-goods"
        },
        children: [
            {
                path: "/user/userList",
                name: "userList",
                component: () => import("@/views/user/userList.vue"),
                meta: { title: "用户列表" }
            }
        ],
    },
    //异业资源
    {
        path: "/yiye",
        component: Menu,
        redirect: "/yiye/yiyepage",
        name: "yiye",
        meta: {
            title: "异业资源",
            icon: "el-icon-s-goods"
        },
        children: [
            {
                path: "/yiye/yiyepage",
                name: "yiyepage",
                component: () => import("@/views/yiye/yiyepage.vue"),
                meta: { title: "异业流水" }
            }
        ],
    },
];

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
