import moment from 'moment';
import { Message, MessageBox } from 'element-ui';

export class Foundation {
    public static dateFormat(date: any, pattern?: string) {
        return date && moment(date).format(pattern || "YYYY-MM-DD HH:mm:ss") || "";
    }

    public static deepClone(object: any) {
        let str;
        let newobj: any = object.constructor === Array ? [] : {};
        if (typeof object !== 'object') {
            return object;
        } else if (window.JSON) {
            str = JSON.stringify(object);
            newobj = JSON.parse(str);
        } else {
            for (const i in object) {
                if (object.hasOwnProperty(i)) {
                    newobj[i] = typeof object[i] === 'object' ? this.deepClone(object[i]) : object[i];
                }
            }
        }
        return newobj;
    }

    public static get(obj: any, key: string, defaultValue?: any) {
        let result: any = obj;
        for (let k of key.split('.')) {
            if (result[k]) {
                result = result[k];
            } else {
                result = false;
                break;
            }
        }
        return result || defaultValue;
    }

    public static resetParams(obj: Object, isDelete: boolean = false) {
        let result = this.deepClone(obj);
        for (let key in result) {
            if ((!result[key] && result[key] !== 0 && result[key] !== false && result[key] !== "") || (isDelete && result[key] === "") || key.indexOf('_') !== -1) delete result[key];
        }
        return result;
    }

    public static beforeDelete(cb: () => void, message?: string, cancelMessage?: string) {
        MessageBox.confirm(message || '此操作将永久删除该数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            cb && cb();
        }).catch(() => {
            Message({ type: 'info', message: cancelMessage || '已取消删除' });
        });
    }

    public static findInArray(array: any, key: string, curV: any) {
        return array.find((v: any) => v[key] == curV) || {};
    }

    public static transNum(num: any) {
        return ["特", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"][num];
    }

    public static formatNum(price: string | number) {
        if (typeof price !== 'number') return price
        return String(Number(price)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    //将大数额转成以万为单位的数字
    public static toMyriad(wan: number) {
        return wan >= 10000 ? `${(wan / 10000).toFixed(0)}万` : wan;
    }

    // 将标准的金额转换为人民币“元”单位
    public static toYUAN(money: number) {
        return money && (money / 100000.0).toFixed(2) || 0;
    }

    //将民币“元”转换为标准的金额
    public static toMoney(yuan: number) {
        return parseInt((yuan && yuan * 100000 || 0).toFixed(0) + "");
    }

    public static resetDate(obj: object, isSec: boolean = false, props: any = ["beginTime", "endTime"]) {
        let result = this.deepClone(obj);
        if (result.date) {
            result[props[0]] = isSec ? result.date[0] / 1000 : result.date[0];
            result[props[1]] = isSec ? result.date[1] / 1000 : result.date[1];
            delete result.date;
        }
        return result;
    }
}